export function useEtherSeo(refSeo) {
    const seoEntry = toRef(refSeo);
    const { public: config } = useRuntimeConfig();
    const url = useRequestURL();

    const seoTitle = computed(() => {
        if (seoEntry.value?.seo?.title) {
            return `${seoEntry.value.seo.title}`;
        } else if (seoEntry.value?.title) {
            return `${config.defaults.siteTitle} | ${seoEntry.value.title}`;
        }

        return config?.defaults?.siteTitle;
    });

    const seoDescription = computed(() => {
        if (seoEntry.value?.seo?.description) {
            return seoEntry.value.seo.description;
        } else if (seoEntry.value?.description) {
            return seoEntry.value.description;
        }

        return config?.defaults?.siteDescription;
    });

    const seoImage = computed(() => {
        if (seoEntry.value?.featuredImage?.[0]?.w768) {
            return seoEntry.value.featuredImage[0];
        } else if (seoEntry.value?.image?.[0]?.w768) {
            return seoEntry.value.image[0];
        } else if (seoEntry.value?.imageMobile?.[0]?.w768) {
            return seoEntry.value.imageMobile[0];
        } else if (seoEntry.value?.imageCard?.[0]?.w768) {
            return seoEntry.value.imageCard[0];
        }
        return '';
    });

    const seoSocial = computed(() => seoEntry?.value?.seo?.social ?? {});
    const seoFacebook = computed(() => seoSocial?.value?.facebook ?? {});
    const seoTwitter = computed(() => seoSocial?.value?.twitter ?? {});
    const seoFacebookImage = computed(() => seoFacebook?.value?.image ?? {});
    const seoTwitterImage = computed(() => seoTwitter?.value?.image ?? {});
    const seoMeta = computed(() => {
        return [
            { hid: 'description', name: 'description', content: seoDescription.value || '' },
            // FACEBOOK
            { hid: 'og:type', property: 'og:type', content: 'website' },
            { hid: 'og:title', property: 'og:title', content: seoFacebook.value.title || seoTitle.value },
            { hid: 'og:description', property: 'og:description', content: seoFacebook.value.description || seoDescription.value || '' },
            { hid: 'og:image', property: 'og:image', content: seoFacebookImage.value.url || seoImage.value.url || '' },
            // TWITTER
            { hid: 'twitter:title', property: 'twitter:title', content: seoTwitter.value.title || seoTitle.value },
            { hid: 'twitter:description', property: 'twitter:description', content: seoTwitter.value.description || seoFacebook.value.description || seoDescription.value || '' },
            { hid: 'twitter:image', property: 'twitter:image', content: seoTwitterImage.value.url || seoFacebookImage.value.url || seoImage.value.w768 || '' },
            // ROBOTS
            { hid: 'robots', name: 'robots', content: seoEntry.value?.seo?.advanced?.robots ?? [] }
        ];
    });

    const metaData = computed(() => {
        return {
            title: seoTitle.value,
            meta: [
                ...seoMeta.value
            ],
            link: [
                {
                    rel: 'canonical',
                    href: seoEntry.value?.seo?.advanced?.canonical || `${config.baseURL}${url.pathname}/`.replace(/\/{2,}/g, '/'),
                },
            ],
        };
    });

    useHead(metaData);
}
